<template>
    <div class="d-price-edit">
        <div v-for="(schemeItem, schemeIndex) in scheme" :key="schemeIndex" class="d-price-edit__item">
            <div class="d-price-edit__item-label">
                {{ schemeItem.label }}
            </div>
            <div class="d-price-edit__item-component">
                <BaseSelect
                    v-if="schemeItem.component.type === 'select'"
                    :value="getFormData[schemeItem.key]"
                    :options="formattedOptions[schemeItem.key]"
                    class="w-full d-price-edit__select"
                    @check="(value) => onChangeComponent(schemeItem.key, schemeItem.component.type, value)"
                />
                <PriceEditDatepicker
                    v-else-if="schemeItem.component.type === 'datepicker'"
                    :selected-dates="getFormData[schemeItem.key]"
                    :access-dates="formattedOptions[schemeItem.key]"
                    class="w-full"
                    @onChange="(value) => onChangeComponent(schemeItem.key, schemeItem.component.type, value)"
                />
                <BaseTabList
                    v-else-if="schemeItem.component.type === 'tabs'"
                    :tab-list="formattedOptions[schemeItem.key]"
                    :tab-id-active="getFormData[schemeItem.key]"
                    class="w-full d-price-edit__tabs"
                    theme="filter"
                    type="toggle"
                    @update:tabIdActive="(value) => onChangeComponent(schemeItem.key, schemeItem.component.type, value)"
                />
                <div v-else-if="schemeItem.component.type === 'row'" class="d-price-edit__row">
                    <div
                        v-for="(colItem) in schemeItem.component.items" :key="colItem.key"
                        class="d-price-edit__col"
                        :style="{width: colItem.width}"
                    >
                        <div class="d-price-edit__col-component">
                            <BaseInput
                                v-if="colItem.component.type === 'input'"
                                :value="getFormData[colItem.key]"
                                :mask="colItem.component.props.mask"
                                placeholder="0"
                                theme="filter"
                                class="d-price-edit__col-input"
                                @onInput="(value) => onChangeComponent(colItem.key, colItem.component.type, value)"
                            />
                            <BaseTabList
                                v-else-if="colItem.component.type === 'tabs'"
                                :tab-list="formattedOptions[colItem.key]"
                                :tab-id-active="getFormData[colItem.key]"
                                theme="filter"
                                type="toggle"
                                class="d-price-edit__col-tabs"
                                @update:tabIdActive="(value) => onChangeComponent(colItem.key, colItem.component.type, value)"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <BaseButton
            view="secondary"
            :disabled="isDisabledButton"
            @click="onSubmitForm"
        >
            Применить
        </BaseButton>
    </div>
</template>

<script>
import BaseSelect from "@/components/Base/BaseSelect.vue";
import PriceEditDatepicker from "@/components/pages/price/PriceEditDatepicker.vue";
import BaseTabList from "@/components/Base/BaseTabList.vue";
import BaseInput from "@/components/Base/BaseInput.vue";
import BaseButton from "@/components/Base/BaseButton.vue";
import {isEmpty} from "lodash";

export default {
    name: "PriceEdit",
    components: {
        BaseButton,
        BaseInput,
        BaseTabList,
        PriceEditDatepicker,
        BaseSelect
    },
    emits: ['onSubmit'],
    props: {
        isLoading: {
            type: Boolean,
            default: false
        },
        options: {
            type: Object,
            default: () => {
            }
        },
        selectedLength: {
            type: Number,
            default: 0
        }
    },
    data: () => ({
        filtersKeys: {
            priceType: 'change_attribute',
            dates: 'dates',
            changeType: 'change_type',
            changeValue: 'change_value',
            valueType: 'value_type'
        },
        formData: {},
    }),
    computed: {
        isDisabledButton() {
            return this.selectedLength === 0 || !this.isFormFilled || this.isLoading;
        },
        getFormData() {
            return this.formData;
        },
        formattedOptions() {
            let valueTypeOptions = [
                {
                    id: 'number',
                    title: '₽'
                },
            ];

            if (this.formData[this.filtersKeys.changeType] === 'decrease') {
                valueTypeOptions.unshift({
                    id: 'percent',
                    title: '%'
                });
            }

            let result = {
                [this.filtersKeys.priceType]: [
                    {
                        id: 'offer_price',
                        // value: 'Цена без НДС'
                        value: 'Аренда'
                    },
                    {
                        id: 'install_price',
                        value: 'Монтаж'
                    },
                    {
                        id: 'print_price',
                        value: 'Печать'
                    },
                    {
                        id: 'additional_install_price',
                        value: 'Доп. монтаж'
                    }
                ],
                [this.filtersKeys.changeType]: [
                    {
                        id: 'edit',
                        title: 'Фикс'
                    },
                    {
                        id: 'decrease',
                        title: 'Скидка'
                    }
                ],
                [this.filtersKeys.valueType]: valueTypeOptions,
                ...this.options
            };

            return result;
        },
        scheme() {
            let result = [
                {
                    label: "Тип цены",
                    key: this.filtersKeys.priceType,
                    component: {
                        type: "select"
                    }
                },
                {
                    label: "Период",
                    key: this.filtersKeys.dates,
                    component: {
                        type: "datepicker"
                    }
                },
                {
                    label: "Действие",
                    key: this.filtersKeys.changeType,
                    component: {
                        type: "tabs"
                    }
                },
                {
                    component: {
                        type: "row",
                        items: [
                            {
                                key: this.filtersKeys.changeValue,
                                width: '120%',
                                component: {
                                    type: "input",
                                    props: {
                                        mask: /[^0-9]/g
                                    }
                                }
                            },
                            {
                                key: this.filtersKeys.valueType,
                                width: '100%',
                                component: {
                                    type: "tabs",
                                }
                            }
                        ]
                    }
                }
            ];

            return result;
        },
        isFormFilled() {
            let filledCount = 0;
            Object.keys(this.formData).forEach(key => {
                const value = this.formData[key];

                if (!isEmpty(value)) {
                    filledCount++;
                }
            });
            return Object.keys(this.filtersKeys).length === filledCount;
        },
        formDataChangeType() {
            return String(this.formData?.change_type ?? '');
        }
    },
    watch: {
        formDataChangeType() {
            this.formData = { ...this.formData, value_type: 'number' };
        }
    },
    created() {
        this.setDefaultFormData();
    },
    methods: {
        setDefaultFormData() {
            this.formData = {
                [this.filtersKeys.dates]: [],
                [this.filtersKeys.priceType]: 'offer_price',
                [this.filtersKeys.changeType]: 'edit',
                [this.filtersKeys.valueType]: 'number',
                [this.filtersKeys.changeValue]: ''
            }
        },
        onChangeComponent(key, type, value) {
            if (type === 'select') {
                this.formData[key] = value.id;
            }

            if (type === 'datepicker') {
                this.formData[key] = [];
                value.forEach(item => {
                    let strMonth = item.month > 9 ? item.month : "0" + item.month;
                    this.formData[key].push(item.year + "-" + strMonth + "-01");
                });
            }

            if (type === 'tabs') {
                this.formData[key] = value;

                if (key === this.filtersKeys.changeType) {
                    if (value === 'decrease') {
                        this.formData[this.filtersKeys.valueType] = 'number';
                    }

                    this.formData[this.filtersKeys.changeValue] = '';
                }
            }

            if (type === 'input') {
                this.formData[key] = value;
            }
        },
        onSubmitForm() {
            this.$emit('onSubmit', this.formData);
        }
    }
}
</script>


<style scoped lang="scss">
.w-full {
    width: 100%;
}

.d-price-edit {
    &__item {
        &:not(:last-child) {
            margin-bottom: 12px;
        }

        &-label {
            color: var(--d-color--gray-500);
            font-size: var(--d-font-size--xs);
            font-weight: var(--d-font-weight--regular);
            line-height: 1.2;

            & + .d-price-edit__item-component {
                margin-top: 4px;
            }
        }
    }

    &__tabs.is-theme--filter {
        padding: 4px;
        background-color: var(--d-color--gray-200);
    }

    &__select {
        ::v-deep .select-preview {
            padding: 9px 12px;
            color: var(--d-color--text-primary);
        }
    }

    &__row {
        display: flex;
        align-items: stretch;
        justify-content: flex-start;
    }

    &__col {
        margin-right: 4px;

        &-component {
            height: 100%;
        }

        &-input,
        &-tabs {
            height: 100%;
        }
    }
}
</style>
