<template>
    <div class="d-price-actions">
        <div class="d-price-actions__count">
            Найдено: <span>{{ totalCount.toLocaleString('ru') || 0 }}</span> {{ sidesText }}
        </div>
        <ActionsPanel :scheme="schemeActions" @onClickAction="onClickAction"/>

        <div class="price-table-actions__description">
            * Все цены указаны до налога оператора
        </div>
    </div>
</template>

<script>
import ActionsPanel from "@/components/Tables/ActionsPanel.vue";

export default {
    name: "PriceTableActions",
    components: {
        ActionsPanel,
    },
    emits: ['onClickAction'],
    props: {
        isLoadingExport: {
            type: Boolean,
            default: false,
        },
        sides: {
            type: Array,
            default: () => []
        },
        totalCount: {
            type: Number,
            default: 0
        }
    },
    computed: {
        schemeActions() {
            let result = [
                {
                    key: 'select-all',
                    label: "Выбрать всё",
                    iconComponentName: "IconSelectAll",
                },
                {
                    key: 'export',
                    label: "Скачать",
                    iconComponentName: "IconDownload",
                    disabled: this.isLoadingExport,
                    disabledLabel: 'Дождитесь загрузки предыдущего файла'
                },
                {
                    key: 'send',
                    label: 'Отправить',
                    iconComponentName: "IconSend"
                },
                // {
                //     key: 'remove',
                //     label: 'Удалить',
                //     iconComponentName: "IconDelete"
                // }
            ];

            return result;
        },
        sidesText() {
            // let result = '';
            // const textArray = [];

            // if (this.sides[0] && "title" in this.sides[0] && "value" in this.sides[0]) {
            //     const side = this.sides[0];
            //     textArray.push(`${side.title} ${side.value.replace(/\D/g, '')}`);
            // }

            // if (this.sides[1] && "title" in this.sides[0] && "value" in this.sides[1]) {
            //     const side = this.sides[1];
            //     textArray.push(`${side.title} ${side.value.replace(/\D/g, '')}`);
            // }

            // result = `(${textArray.join(' / ')})`

            // return result;

            const textArray = this.sides.map(side => `${side?.title ?? ''} ${side?.value.replace(/\D/g, '')}`);
            const result = `(${textArray.join(' / ')})`;

            return result;
        }
    },
    methods: {
        onClickAction(key) {
            if (key === 'export' && this.isLoadingExport) return;

            this.$emit('onClickAction', key);
        }
    }
}
</script>

<style scoped lang="scss">
.d-price-actions {
    padding: 3px 16px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &__count {
        margin-right: 10px;
        color: var(--d-color--text-primary);
        font-size: var(--d-font-size--sm);
        font-weight: var(--d-font-weight--regular);
        line-height: 1;

        span {
            font-weight: var(--d-font-weight--bold);
        }
    }
}

.price-table-actions {
    &__description {
        margin-left: auto;
    }
}
</style>
